import React from 'react'

const SVG = ({
  style = {},
  fill = 'inherit',
  width = 40,
  className = '',
  viewBox = `-5 -5 100 50`,
}) => (
  <svg
    width={width}
    style={style}
    height={width / 2}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill={fill}>
      <path d="M40.88,14H39v10h2.185c1.815,0,3.209-0.213,4.184-1.129c0.976-0.916,1.463-2.125,1.463-3.872   c0-1.746-0.487-2.868-1.463-3.809C44.395,14.249,42.897,14,40.88,14z" />
      <path d="M28.123,14.462C27.503,14.03,26.541,14,25.235,14H23v6h2.642c1.323,0,2.222-0.465,2.697-0.999   c0.475-0.534,0.712-1.319,0.712-2.346C29.051,15.629,28.741,14.895,28.123,14.462z" />
      <path d="M77,0H5C2.239,0,0,2.238,0,5v28c0,2.762,2.239,5,5,5h72c2.762,0,5-2.238,5-5V5C82,2.238,79.762,0,77,0z    M31.201,21.493C29.92,22.511,27.965,23,25.337,23H23v5h-4V10h6.286c2.73,0,4.719,0.64,5.965,1.699s1.87,2.732,1.87,4.929   S32.481,20.476,31.201,21.493z M48.346,25.584C46.692,27.195,44.17,28,40.778,28H35V10h5.981c3.171,0,5.604,0.885,7.301,2.437   s2.544,3.765,2.544,6.529C50.826,21.73,50,23.973,48.346,25.584z M65,14h-8v4h8v3h-8v7h-4V10h12V14z" />
    </g>
  </svg>
)

export default SVG
