import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'

import Button from '../components/button'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Icon from '../components/icon'
import StyledBackgroundHero from '../components/background-header'
import CourseSelector from '../components/forms/course-selector'
import { VerticalSpace, CenterTextDiv } from '../components/common'

import check from '../images/haken-01.svg'
import { useForm } from 'react-form'
import { courses } from '../components/forms/data'

const Kurse = ({ data }) => {
  const coursePlanDescription = data.coursePlan.description
  const coursePlanDownloadUrl = data.coursePlan.file.url
  const imageData = data.desktop.childImageSharp.fluid
  const DownloadButton = Button.withComponent('a')

  const { Form } = useForm({
    onSubmit: (values) => {
      console.log('Huzzah!', values)
    },
  })

  return (
    <Layout hero={<StyledBackgroundHero imageData={imageData} />}>
      <SEO title="Kurse" />
      <CenterTextDiv>
        <h2>Hatha Yoga mit Klangschalenbegleitung</h2>
        <h3>für Anfänger, Wiedereinsteiger und Übende</h3>
        <br />
        <p>
          In diesem Kurs kannst Du mit Asanas-, Atem- und Entspannungsübungen
          mehr Kraft, Energie, Gelassenheit und Vertrauen zu Dir selbst
          entwickeln. Harmonische Schwingungen der Klangschalen unterstützen den
          Entspannungsprozess.
        </p>
        <br />
        <p>
          <strong>Lade Dir hier die aktuelle Kursübersicht herunter</strong>
        </p>
        <DownloadButton href={coursePlanDownloadUrl} target="_blank">
          <div>Jetzt herunterladen</div>
          <Icon style={{ marginLeft: 10 }} name="pdf" width={40} />
        </DownloadButton>
        <br />

        <p>{coursePlanDescription}</p>

        <Button to="/buchen" inverted>
          Kursplatz anfragen
        </Button>

        <VerticalSpace />

        <h2>Reguläre Kurszeiten</h2>
        <p>
          Der Yoga Unterricht findet immer am selben Wochentag zur selben
          Uhrzeit statt. Solltest Du einmal verhindert sein, kann ein
          Ersatztermin bei freien Kapazitäten vereinbart werden.
        </p>

        <Form>
          <CourseSelector
            formId="course_day"
            data={courses}
            onSelect={() => {}}
          />
        </Form>

        <VerticalSpace />

        <h2>Preise</h2>
        <p>
          <strong>Für nicht angemeldete Kursteilnehmer</strong>
          <br />
          Einzelstunde: 16€ | Probestunde: 10€
        </p>
        <p>
          <strong>Für Kursteilnehmer</strong>
          <br />
          10 x 90min: 130€
          <br />
          bei Teilnahme an mehreren Kursen pro Woche 10% Ermäßigung <br />
          zusätzliche Einzelstunden: 13€
        </p>

        <div
          css={css`
            font-weight: 700;
            display: flex;
            justify-content: space-around;

            & span {
              display: flex;
              align-items: center;

              & img {
                width: 1.5rem;
                margin-right: 0.5rem;
              }
            }

            @media (max-width: 400px) {
              flex-direction: column;

              & span {
                font-size: 1.5rem;
                flex-direction: column;
                margin-bottom: 1.5rem;

                & img {
                  width: 3rem;
                  display: block;
                }
              }
            }
          `}
        >
          <span>
            <img src={check} alt="Haken Symbol" /> Bezuschussung gesetzlicher
            Krankenkassen
          </span>
          <span>
            <img src={check} alt="Haken Symbol" />
            Quereinstieg möglich
          </span>
          <span>
            <img src={check} alt="Haken Symbol" />
            Kooperationspartner der AOK
          </span>
        </div>
        <br />
        <br />
        <Button to="/buchen" inverted>
          Kursplatz anfragen
        </Button>
      </CenterTextDiv>
    </Layout>
  )
}

export const query = graphql`
  query {
    coursePlan: contentfulAsset(
      contentful_id: { eq: "5Mw5xKbDg6V30E2MMAGJlH" }
    ) {
      id
      file {
        url
      }
      description
    }

    desktop: file(
      relativePath: { eq: "body-stretching-yoga-beauty-35987@3x.png" }
    ) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Kurse
