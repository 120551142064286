import React from 'react'

import PDF from './pdf'
import ArrowCircleRight from './arrowCircleRight'

const Icon = props => {
  switch (props.name) {
    case 'pdf':
      return <PDF {...props} />
    case 'arrowCircleRight':
      return <ArrowCircleRight {...props} />
    default:
      return null
  }
}

export default Icon
